import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "login-1",
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-1")
    ),
  },
  {
    key: "login-2",
    path: `${AUTH_PREFIX_PATH}/login-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-2")
    ),
  },
  {
    key: "register-1",
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-1")
    ),
  },
  {
    key: "register-2",
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-2")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "error-page-1",
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "error-page-2",
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/apps/dashboard/")),
  },
  {
    key: "profile.password",
    path: `${APP_PREFIX_PATH}/change-password`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/ChangePassword")
    ),
  },
  {
    key: "dashboard.visit-dashboard",
    path: `${APP_PREFIX_PATH}/edit-profile`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/EditProfile")
    ),
  },
  // -------------------------------------------------------------------

  //ADMIN ROUTING

  {
    key: "user.admins",
    path: `${APP_PREFIX_PATH}/admins/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/admins/AdminList")
    ),
  },
  {
    key: "user.admins.add",
    path: `${APP_PREFIX_PATH}/admins/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/admins/AdminAdd")
    ),
  },
  {
    key: "user.admins.edit",
    path: `${APP_PREFIX_PATH}/admins/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/admins/AdminAdd")
    ),
  },

  //Driver ROUTING

  {
    key: "user.drivers",
    path: `${APP_PREFIX_PATH}/drivers/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/driver/DriverList")
    ),
  },
  {
    key: "user.drivers.add",
    path: `${APP_PREFIX_PATH}/drivers/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/driver/DriverAdd")
    ),
  },
  {
    key: "user.drivers.edit",
    path: `${APP_PREFIX_PATH}/drivers/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/driver/DriverAdd")
    ),
  },
  {
    key: "user.drivers.details",
    path: `${APP_PREFIX_PATH}/drivers/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/driver/DriverDetails")
    ),
  },

  //User Routing

  {
    key: "user.users",
    path: `${APP_PREFIX_PATH}/users/list`,
    component: React.lazy(() => import("views/app-views/pages/user/userList")),
  },
  {
    key: "user.users.add",
    path: `${APP_PREFIX_PATH}/users/add`,
    component: React.lazy(() => import("views/app-views/pages/user/UserAdd")),
  },
  {
    key: "user.users.edit",
    path: `${APP_PREFIX_PATH}/users/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/user/UserAdd")),
  },
  {
    key: "user.users.profile",
    path: `${APP_PREFIX_PATH}/members-management/user/profile/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/profile/UserProfile")
    ),
  },
  {
    key: "user.users",
    path: `${APP_PREFIX_PATH}/users/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/user/UserDetails")
    ),
  },
  //--------------others------------------------
  {
    key: "user.others",
    path: `${APP_PREFIX_PATH}/others/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/others/OthersList")
    ),
  },

  //Roles ===========================>
  {
    key: "roles.list",
    path: `${APP_PREFIX_PATH}/roles/list`,
    component: React.lazy(() => import("views/app-views/pages/roles/RoleList")),
  },

  // Bookings====================>
  {
    key: "bookings.list",
    path: `${APP_PREFIX_PATH}/bookings/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/bookings/BookingsList")
    ),
  },
  {
    key: "bookings.add",
    path: `${APP_PREFIX_PATH}/bookings/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/bookings/AddBookings")
    ),
  },
  {
    key: "bookings.details",
    path: `${APP_PREFIX_PATH}/booking/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/bookings/BookingDetails")
    ),
  },

  {
    key: "bookings.detailstwo",
    path: `${APP_PREFIX_PATH}/booking/detailstwo/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/bookings/BookingDetailsTwo.js")
    ),
  },

  {
    key: "notification.details",
    path: `${APP_PREFIX_PATH}/notification/Notifications`,
    component: React.lazy(() =>
      import("views/app-views/pages/notification/Notifications.js")
    ),
  },

  // Requests====================>
  // {
  //   key: "requests.list",
  //   path: `${APP_PREFIX_PATH}/requests/list`,
  //   component: React.lazy(() =>
  //     import("views/app-views/pages/requests/RequestList")
  //   ),
  // },

  // -------------------------------------------------------------------

  //Categories======================>
  {
    key: "categories.list",
    path: `${APP_PREFIX_PATH}/categories/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/categories/Categories")
    ),
  },

  //Distance======================>
  {
    key: "distance.list",
    path: `${APP_PREFIX_PATH}/distance/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/distance/Distance")
    ),
  },
  {
    key: "distance.add",
    path: `${APP_PREFIX_PATH}/distance/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/distance/AddDistance")
    ),
  },
  //Distance Rate=====================>
  {
    key: "distancerate.list",
    path: `${APP_PREFIX_PATH}/distance-rate/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/distanceRate/DistanceRateList")
    ),
  },
  {
    key: "distance.add",
    path: `${APP_PREFIX_PATH}/distance-rate/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/distanceRate/AddDistanceRate")
    ),
  },

  //City Price=====================>
  {
    key: "distancerate.list",
    path: `${APP_PREFIX_PATH}/city-price/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/cityPrice/CityPriceList")
    ),
  },

  // Service Types====================>
  {
    key: "serviceTypes.list",
    path: `${APP_PREFIX_PATH}/service-types/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/carService/CarServiceList")
    ),
  },
  {
    key: "serviceTypes.add",
    path: `${APP_PREFIX_PATH}/service-types/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/carService/CarServiceAdd")
    ),
  },
  {
    key: "serviceTypes.edit",
    path: `${APP_PREFIX_PATH}/service-types/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/carService/CarServiceAdd")
    ),
  },

  // Car Types===================>
  {
    key: "carTypes.list",
    path: `${APP_PREFIX_PATH}/car-types/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/carTypes/CarTypesList")
    ),
  },
  // -------------------------------------------------------------------

  // Ratings ====================>
  {
    key: "ratings.list",
    path: `${APP_PREFIX_PATH}/ratings/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/ratings/RatingsList")
    ),
  },
  {
    key: "ratings.add",
    path: `${APP_PREFIX_PATH}/ratings/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/ratings/AddRatings")
    ),
  },

  // -------------------------------------------------------------------

  //Permissions
  {
    key: "permission.list",
    path: `${APP_PREFIX_PATH}/permissions/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/permissions/PermissionList")
    ),
  },

  //Service======================================>
  {
    key: "service.customer.list",
    path: `${APP_PREFIX_PATH}/customer-service/list`,
    component: React.lazy(() =>
      import(
        "views/app-views/pages/service/customerServices/CustomerServicesList"
      )
    ),
  },
  {
    key: "service.specialist.list",
    path: `${APP_PREFIX_PATH}/specialist-service/list`,
    component: React.lazy(() =>
      import(
        "views/app-views/pages/service/specialistServices/SpecialistServicesList"
      )
    ),
  },

  {
    key: "cms.settings.list",
    path: `${APP_PREFIX_PATH}/site-settings`,
    component: React.lazy(() =>
      import("views/app-views/pages/setting/SiteSetting")
    ),
  },

  //Testimonials
  {
    key: "testimonials.list",
    path: `${APP_PREFIX_PATH}/testimonials/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/testimonials/TestimonialsList")
    ),
  },
  {
    key: "testimonials.add",
    path: `${APP_PREFIX_PATH}/testimonials/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/testimonials/AddTestimonials")
    ),
  },
  {
    key: "testimonials.edit",
    path: `${APP_PREFIX_PATH}/testimonials/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/testimonials/AddTestimonials")
    ),
  },

  // Transections
  {
    key: "transections.list",
    path: `${APP_PREFIX_PATH}/transactions/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/transections/TransectionsList")
    ),
  },
  {
    key: "transections.add",
    path: `${APP_PREFIX_PATH}/transections/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/transections/AddTransections")
    ),
  },
  {
    key: "transections.edit",
    path: `${APP_PREFIX_PATH}/transections/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/transections/AddTransections")
    ),
  },
  {
    key: "transections.details",
    path: `${APP_PREFIX_PATH}/transections/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/transections/TransectionDetails")
    ),
  },

  //enquiry
  {
    key: "enquiry.list",
    path: `${APP_PREFIX_PATH}/enquiry/list`,
    component: React.lazy(() =>
      import("views/app-views/pages/enquiry/EnquiryList")
    ),
  },
  {
    key: "enquiry.details",
    path: `${APP_PREFIX_PATH}/enquiry/details/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/enquiry/EnquiryDetails")
    ),
  },
  {
    key: "enquiry.add",
    path: `${APP_PREFIX_PATH}/enquiry/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/enquiry/AddEnquery")
    ),
  },

  //FAQ
  {
    key: "faq.list",
    path: `${APP_PREFIX_PATH}/faq/list`,
    component: React.lazy(() => import("views/app-views/pages/faq/FaqList")),
  },
  {
    key: "faq.add",
    path: `${APP_PREFIX_PATH}/faq/add`,
    component: React.lazy(() => import("views/app-views/pages/faq/AddFaq")),
  },
  {
    key: "faq.edit",
    path: `${APP_PREFIX_PATH}/faq/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/faq/AddFaq")),
  },

  //Pages
  {
    key: "page.list",
    path: `${APP_PREFIX_PATH}/pages/list`,
    component: React.lazy(() => import("views/app-views/pages/page/PageList")),
  },
  {
    key: "page.add",
    path: `${APP_PREFIX_PATH}/pages/add`,
    component: React.lazy(() => import("views/app-views/pages/page/PageAdd")),
  },
  {
    key: "page.edit",
    path: `${APP_PREFIX_PATH}/pages/edit/:id`,
    component: React.lazy(() => import("views/app-views/pages/page/PageAdd")),
  },

  //bookingDetailstwo
];
